import { useState, useEffect, useCallback, useRef } from 'react';
import {
  DataGridPro,
  GridColDef,
  GridColumnVisibilityModel,
  GridRowModel,
  GridToolbar,
  useGridApiContext,
  GridRenderCellParams,
  GridRenderEditCellParams,
  getGridStringOperators
} from '@mui/x-data-grid-pro';
import { Autocomplete, Box, Chip, IconButton, Popover, TextField, Tooltip, Typography } from '@mui/material';
import Iconify from '../iconify';
import { useScrapingExistMutation } from 'src/context/api/admin/api';
import { useCurrentWorkspace } from 'src/context/reducers/app-settings';
import { usePopover } from '../custom-popover';
import React from 'react';
import { useGetCampaignsQuery, useGetKeywordsQuery, useGetCountriesQuery, useGetProfileQuery, useGetKeywordInfoKoBiddingQuery, useGetKeywordInfoActiveModeQuery, useCheckActiveModeExistsMutation } from 'src/context/api/liveBidding/api';
import { formatUpdatedAt } from 'src/utils/dates-labels';
import { useLocales } from 'src/locales';

type Country = {
  country_code: string;
  country_name: string;
};

type DataRow = {
  id: number;
  keyword: string;
  country: Country;
  frequency: string;
  device: string[];
  status?: boolean;
  isDeleted?: boolean;
  isDuplicate?: boolean;
};

type EditCellProps = {
  id: number | string;
  value: any;
  field: string;
};

const PlatformEditInputCell: any = ({ id, value, field }: EditCellProps) => {
  const apiRef = useGridApiContext();

  const handleChange = useCallback(
    (event: any, newValue: string[]) => {
      if (newValue.length === 0) {
        return;
      }
      apiRef.current.setEditCellValue({ id, field, value: newValue }, event);
      apiRef.current.stopCellEditMode({ id, field });
    },
    [apiRef, id, field]
  );

  return (
    <Box sx={{
      width: '100%',
      "& .css-wb57ya-MuiFormControl-root-MuiTextField-root": {
        verticalAlign: "middle",
        top: -4
      }
    }}>
      <Autocomplete
        options={['Google Ads', 'Bing Ads']}
        disableClearable
        value={value}
        onChange={handleChange}
        renderInput={(params) => <TextField {...params} variant="outlined" />}
        disableCloseOnSelect
        size="small"
      />
    </Box>
  );
};

const DeviceEditInputCell: any = ({ id, value, field }: EditCellProps) => {
  const apiRef = useGridApiContext();

  const handleChange = useCallback(
    (event: any, newValue: string[]) => {
      if (newValue.length === 0) {
        return;
      }
      apiRef.current.setEditCellValue({ id, field, value: newValue }, event);
      apiRef.current.stopCellEditMode({ id, field });
    },
    [apiRef, id, field]
  );

  return (
    <Box sx={{
      width: '100%',
      "& .css-wb57ya-MuiFormControl-root-MuiTextField-root": {
        verticalAlign: "middle",
        top: -4
      }
    }}>
      <Autocomplete
        multiple
        options={['desktop', 'mobile']}
        disableClearable
        value={value || []}
        onChange={handleChange}
        renderInput={(params) => <TextField {...params} variant="outlined" />}
        disableCloseOnSelect
        size="small"
      />
    </Box>
  );
};

const KeywordEditInputCell = ({ id, value, field, api }: GridRenderEditCellParams) => {
  const workspace: any = useCurrentWorkspace();

  const { data: keywordOptions, isLoading: isLoadingKeywords } = useGetKeywordsQuery({
    client: workspace?.bqTable,
  });

  const handleKeywordChange = (event: any, newValue: string) => {
    api.setEditCellValue({ id, field: 'keyword', value: newValue });
    api.stopCellEditMode({ id, field });
  };

  return (
    <Box sx={{
      width: '100%',
      "& .css-wb57ya-MuiFormControl-root-MuiTextField-root": {
        verticalAlign: "middle",
        top: -4
      }
    }}>
      <Autocomplete
        disableClearable
        options={keywordOptions || []}
        value={value || ''}
        onChange={(event, newValue) => handleKeywordChange(event, newValue)}
        renderInput={(params) => <TextField {...params} variant="outlined" />}
        disabled={isLoadingKeywords}
        size='small'
      />
    </Box>
  );
};

const CountryEditInputCell = ({ id, value, field, api, row, countryRef }: GridRenderEditCellParams) => {
  const workspace: any = useCurrentWorkspace();

  const keyword = row.keyword;
  const profile = row.account;

  const { data: countryOptions, isLoading: isLoadingCountries } = useGetCountriesQuery({
    client: workspace?.bqTable,
    profile: profile,
    keyword: keyword,
  });

  const countriesArray: any = countryOptions ? Object.values(countryOptions) : [];

  const handleCountryChange = (event: any, newValue: Country | null) => {
    countryRef.current = newValue;

    if (newValue) {
      api.setEditCellValue({ id, field: 'country', value: [newValue.country_name] });
    }
    api.stopCellEditMode({ id, field });
  };

  const selectedCountry = countriesArray.find((opt: any) => opt.country_name === value[0]) || null;

  return (
    <Box sx={{
      width: '100%',
      "& .css-wb57ya-MuiFormControl-root-MuiTextField-root": {
        verticalAlign: "middle",
        top: -4
      }
    }}>
      <Autocomplete
        disableClearable
        options={countriesArray || []}
        getOptionLabel={(option) => option.country_name || ''}
        value={selectedCountry}
        onChange={handleCountryChange}
        renderOption={(props, option) => (
          <Box component="li" {...props}>
            <Iconify sx={{ mr: 1 }} icon={`circle-flags:${option.country_code.toLowerCase()}`} />
            {option.country_name}
          </Box>
        )}
        renderInput={(params) => <TextField {...params} variant="outlined" />}
        disabled={isLoadingCountries}
        size="small"
        isOptionEqualToValue={(option, value) =>
          option.country_code === value?.country_code
        } // Compare les objets de manière plus précise
      />
    </Box>
  );
};


const CampaignEditInputCell = ({ id, value, field, api, row, campaignOptionsRef }: GridRenderEditCellParams) => {
  const workspace: any = useCurrentWorkspace();

  const keyword = row.keyword;
  const platform = row.platform_scraping;
  const profile = row.account;
  const country = row.country;

  const { data: campaignOptions, isLoading: isLoadingcampaign_name } = useGetCampaignsQuery(
    { client: workspace?.bqTable, keyword: keyword, profile, match_type: "Exact", country, platform },
  );

  useEffect(() => {
    if (campaignOptions) {
      campaignOptionsRef.current = campaignOptions;
    }
  }, [campaignOptions]);


  const handleCampaignChange = (event: any, newValue: any) => {
    api.setEditCellValue({ id, field: 'campaign_name', value: newValue?.campaign_name});
    api.stopCellEditMode({ id, field });
  };

  const selectedcampaign_name = campaignOptions?.filter((opt: any) => value?.includes(opt.campaign_name)) || [];

  return (
    <Box sx={{
      width: '100%',
      "& .css-wb57ya-MuiFormControl-root-MuiTextField-root": {
        verticalAlign: "middle",
        top: -4
      }
    }}>
      <Autocomplete
        multiple={false}
        disableClearable
        options={campaignOptions || []}
        getOptionLabel={(option) => option.campaign_name || ''}
        value={selectedcampaign_name}
        onChange={handleCampaignChange}
        renderInput={(params) => <TextField {...params} variant="outlined" />}
        size="small"
        isOptionEqualToValue={(option, value) => option.campaign_id === value?.campaign_id}
      />
    </Box>
  );
};


const AccountEditInputCell = ({ id, value, field, api, row, accountOptionsRef }: GridRenderEditCellParams) => {
  const workspace: any = useCurrentWorkspace();

  // Récupérer les options de campagnes en fonction du keyword
  const keyword = row.keyword;

  // Récupérer les options de profils à partir de l'API
  const { data: profileOptions, isLoading: isLoadingProfiles } = useGetProfileQuery({
    client: workspace?.bqTable,
    keyword
  });

  // Mettre à jour la référence des options d'account lorsque les options changent
  useEffect(() => {
    if (profileOptions) {
      accountOptionsRef.current = profileOptions; // Met à jour la référence
    }
  }, [profileOptions]);

  const handleProfileChange = (event: any, newValue: any) => {
    if (newValue) {
      // Mettre à jour la valeur de l'account et de l'account_id
      api.setEditCellValue({ id, field: 'account', value: newValue.profile });
      api.setEditCellValue({ id, field: 'account_id', value: newValue.profile_id });

      // Stopper l'édition du champ account
      api.stopCellEditMode({ id, field });
    }
  };

  return (
    <Box sx={{
      width: '100%',
      "& .css-wb57ya-MuiFormControl-root-MuiTextField-root": {
        verticalAlign: "middle",
        top: -4
      }
    }}>
      <Autocomplete
        disableClearable
        options={profileOptions || []} // Charger les options de profils
        getOptionLabel={(option) => option.profile} // Afficher profil et profile_id
        value={profileOptions?.find((option: any) => option.profile === value) || null} // La valeur actuelle du champ
        onChange={handleProfileChange} // Appeler la fonction handleProfileChange lors d'une modification
        renderInput={(params) => <TextField {...params} variant="outlined" />}
        disabled={isLoadingProfiles} // Désactiver pendant le chargement
        size='small'
      />
    </Box>
  );
};

const KeywordCell = (params: GridRenderCellParams) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const isDuplicate = params.row.isDuplicate;

  const keyword = params.row.keyword;
  const country = params.row.country;

  return (
    <>
      <div
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        <Chip
          size="small"
          label={
            params.row.isDuplicate
              ? `🚫 ${params.row.keyword}`
              : params.row.keyword
          }
          style={{ margin: 2 }}
        />

      </div>
      {isDuplicate && (
        <Popover
          id="mouse-over-popover"
          sx={{
            pointerEvents: 'none',
            width: 700,
          }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <Box sx={{ p: 1 }}>
            <Typography>Unable to add the following keyword: {keyword}.</Typography>
            <Typography fontSize={14} sx={{ mt: 1 }}>Live monitoring of the following keyword {keyword} has already been configured for the country '{country}.</Typography>
          </Box>
        </Popover>
      )}
    </>
  );
};

const defaultStringOperators = getGridStringOperators();

// Clone default string operators
const operators = defaultStringOperators.filter(
  (operator) => operator.value !== 'isEmpty' && operator.value !== 'isNotEmpty'
);

// Ajoutez vos opérateurs personnalisés si nécessaire
const customStringOperators = [
  ...operators,
  // Ajoutez ici vos opérateurs personnalisés si vous en avez
];

const createColumns = (
  deleteItem: (id: number | string) => void,
  resetItem: (id: number | string) => void,
  editedRows: Map<number | string, Partial<GridRowModel>>,
  campaignOptionsRef: React.RefObject<any>,
  accountOptionsRef: React.RefObject<any>,
  rows: any,
  countryRef: React.RefObject<any>,
  setEditedRows: any,
  currentLang: any,
  setDeletedRowIds: React.Dispatch<React.SetStateAction<(number | string)[]>>, // Ajout de setDeletedRowIds
  setRows: React.Dispatch<React.SetStateAction<DataRow[]>> // Ajout de setRows
): GridColDef[] => [
    {
      field: 'Actions',
      headerName: '',
      width: 100,
      sortable: false,
      filterable: false,
      disableExport: true,
      renderCell: (params: GridRenderCellParams) => (
        <ActionMenu
          params={params}
          editedRow={editedRows.has(params.id)}
          deleteItem={deleteItem}
          resetItem={resetItem}
        />
      ),
    },
    {
      field: 'keyword',
      headerName: 'Keyword',
      flex: 1,
      editable: true,
      filterOperators: customStringOperators,
      renderCell: (params: GridRenderCellParams) => <KeywordCell {...params} />,
      renderEditCell: (params: GridRenderEditCellParams) => (
        <KeywordEditInputCell {...params} />
      ),
    },
    {
      field: 'account',
      headerName: 'Account',
      type: 'singleSelect',
      valueOptions: Array.from(new Set(rows.map((row: any) => row.account))), // Dynamically fetch unique values
      editable: true,
      flex: 1,
      renderEditCell: (params: GridRenderEditCellParams) => (
        <AccountEditInputCell accountOptionsRef={accountOptionsRef} {...params} />
      ),
    },
    {
      field: 'device_gads',
      headerName: 'Active Mode Device(s)',
      flex: 1,
      editable: true,
      type: 'custom',
      renderCell: (params: GridRenderCellParams) => (
        <span>
          {params.value.join(', ')}
        </span>
      ),
      renderEditCell: (params: GridRenderEditCellParams) => (
        <DeviceEditInputCell {...params} />
      ),
    },
    {
      field: 'country',
      headerName: 'Country',
      editable: true,
      flex: 1,
      type: 'singleSelect',
      valueGetter: (params) => params[0],
      valueOptions: Array.from(new Set(rows.map((row: any) => row.country[0]))), // Dynamically fetch unique values
      filterable: true,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Box display="flex" alignItems="center">
            <Iconify sx={{ mr: 1 }} icon={`circle-flags:${params.row.country_code}`} />
            {params.row.country}
          </Box>
        );
      },
      renderEditCell: (params: GridRenderEditCellParams) => (
        <CountryEditInputCell
          {...params}
          countryRef={countryRef}
          setEditedRows={setEditedRows} // Passez setEditedRows ici
          setDeletedRowIds={setDeletedRowIds} // Directement intégré ici
          setRows={setRows} // Directement intégré ici
        />
      ),
    },
    {
      field: 'platform',
      headerName: 'Platform',
      flex: 1,
      editable: true,
      renderEditCell: (params: GridRenderEditCellParams) => (
        <PlatformEditInputCell {...params} />
      ),
      type: 'singleSelect',
    },
    {
      field: 'campaign_name',
      headerName: 'Campaign',
      flex: 1,
      editable: true,
      valueGetter: (params) => params[0],
      valueOptions: Array.from(new Set(rows.map((row: any) => row.campaign_name[0]))), // Dynamically fetch unique values
      type: 'singleSelect',
      renderEditCell: (params: GridRenderCellParams) => <CampaignEditInputCell campaignOptionsRef={campaignOptionsRef} {...params} />,
    },
    {
      field: 'device',
      headerName: 'Scraping Device(s)',
      flex: 1,
      editable: true,
      type: 'custom',
      renderCell: (params: GridRenderCellParams) => (
        <span>
          {params.value.join(', ')}
        </span>
      ),
      renderEditCell: (params: GridRenderEditCellParams) => (
        <DeviceEditInputCell {...params} />
      ),
    },
    {
      field: 'created_at',
      headerName: 'Created At',
      renderCell: (params: GridRenderCellParams) => (
        <span>
          {formatUpdatedAt(params?.row?.created_at, currentLang?.value)}
        </span>
      ),
      flex: 1,
    },
    {
      field: 'status',
      headerName: 'Status',
      editable: true,
      type: 'boolean',
      renderCell: (params: GridRenderCellParams) => (
        <Tooltip title={params.value ? `Active - Edited on ${formatUpdatedAt(params.row.updated_at, currentLang?.value)}` : `Inactive - Edited on ${formatUpdatedAt(params.row.updated_at, currentLang?.value)}`} arrow>
          <Box display="flex" alignItems="center" justifyContent="center">
            {params.value ? (
              <Iconify icon={"material-symbols:check"} />
            ) : (
              <Iconify icon={"mdi:times"} />
            )}
          </Box>
        </Tooltip>
      ),
      flex: 1,
    },
  ];

// Action menu component
type ActionMenuProps = {
  params: GridRenderCellParams;
  editedRow: boolean;
  deleteItem: (id: number | string) => void;
  resetItem: (id: number | string) => void;
};

const ActionMenu = ({ params, deleteItem, resetItem, editedRow }: ActionMenuProps) => {
  const handleDelete = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    deleteItem(params.row.id);
  };

  const handleReset = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    resetItem(params.row.id);
  };

  return (
    <div>
      <IconButton
        onClick={handleReset}
        onMouseDown={(e) => e.stopPropagation()}
        onMouseUp={(e) => e.stopPropagation()}
        disabled={!editedRow}
      >
        <Iconify sx={{ opacity: !editedRow ? 0.2 : 1 }} icon="material-symbols:refresh" />
      </IconButton>

      <IconButton
        onClick={handleDelete}
        onMouseDown={(e) => e.stopPropagation()}
        onMouseUp={(e) => e.stopPropagation()}
      >
        <Iconify icon="solar:trash-bin-trash-bold" />
      </IconButton>
    </div>
  );
};

type Props = {
  data: DataRow[];
  pageSize: number;
  deleteItem?: (ids: (number | string)[]) => void;
  handleSave: (editedRows: { id: string | number; changes: Partial<GridRowModel> }[]) => void;
  onEdit: (hasEditedRows: boolean) => void;
  onApply: boolean;
  onReset: boolean;
};

export default function DataGridCustomLiveBiddingBasedKoBidding({
  data,
  pageSize,
  deleteItem,
  handleSave,
  onEdit,
  onApply,
  onReset,
}: Props) {

  const campaignOptionsRef = useRef([]);
  const accountOptionsRef = useRef([]);
  const countryRef: any = useRef();

  const [rows, setRows]: any = useState<DataRow[]>(data);
  const [initialRows] = useState<Map<number | string, DataRow>>(new Map(data.map(row => [row.id, { ...row }])));
  const [selectionModel, setSelectionModel] = useState<(number | string)[]>([]);
  const [editedRows, setEditedRows] = useState<Map<number | string, Partial<GridRowModel>>>(new Map());
  const [deletedRowIds, setDeletedRowIds] = useState<(number | string)[]>([]);
  const popover = usePopover();
  const [pendingChanges, setPendingChanges] = useState<Map<number | string, Partial<DataRow>>>(new Map());
  const { currentLang, t } = useLocales();

  const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>({
    id: true,
    campaign_id: false,
    account_id: false
  });

  const [paginationModel, setPaginationModel] = useState({
    pageSize: pageSize,
    page: 0,
  });

  const handleChangeColumnVisibilityModel = useCallback((newModel: GridColumnVisibilityModel) => {
    setColumnVisibilityModel(newModel);
  }, []);

  const handlePaginationModelChange = useCallback((newModel: any) => {
    setPaginationModel(newModel);
  }, []);

  const [scrapingExist] = useScrapingExistMutation();
  const workspace: any = useCurrentWorkspace();

  const handleProcessRowUpdateError = (error: any) => {
    console.error("Error updating row:", error);
    // Affichez un message d'erreur utilisateur si nécessaire
    alert("An error occurred while updating the row. Please try again.");
  };

  const [activeModeExist] = useCheckActiveModeExistsMutation();

  const [selectedCampaign, setSelectedCampaign]: any = useState<{ campaign_name: string, rowId: number | string } | null>(null);

  const processRowUpdate = useCallback(
    async (newRow: GridRowModel, oldRow: GridRowModel) => {
      const initialRow: any = initialRows.get(newRow.id);
      const isModified = Object.keys(newRow).some((key) => newRow[key] !== initialRow?.[key]);

      // Vérifiez si `keyword`, `country`, `account`, ou `campaign_name` ont changé
      const keywordChanged = newRow.keyword !== oldRow.keyword;
      const accountChanged = newRow.account !== oldRow.account;
      const campaignChanged = newRow.campaign_name !== oldRow.campaign_name;
      const countryChanged = newRow.country !== oldRow.country;
      const platformChanged = newRow.platform !== oldRow.platform;
      let isDuplicate = false;
      let isDeleted = false;

      // Logique de mise à jour de `platform_scraping` en fonction de `platform`
      if (platformChanged) {

        newRow.campaign_name = [];
        newRow.campaign_id = [];

        if (newRow.platform === "Google Ads") {
          newRow.platform_scraping = ["Google"];
        } else if (newRow.platform === "Bing Ads") {
          newRow.platform_scraping = ["Bing"];
        } else {
          newRow.platform_scraping = []; // Valeur par défaut si platform est autre
        }
      }

      // Mettre à jour les champs en fonction des changements détectés
      if (keywordChanged) {
        Object.assign(newRow, {
          keyword_id: "",
          country: [],
          country_code: [],
          campaign_name: [],
          campaign_id: [],
          account: '',
          account_id: '',
          ad_group: '',
          ad_group_id: '',
        });
      }

      if (accountChanged) {
        newRow.keyword_id = "";
        newRow.campaign_name = [];
        newRow.campaign_id = [];

        // Met à jour `account_id` en fonction du compte sélectionné
        const selectedAccount: any = accountOptionsRef.current.find((opt: any) => opt.profile === newRow.account);
        newRow.account_id = selectedAccount ? selectedAccount.profile_id : '';
      }

      if (campaignChanged) {
        setSelectedCampaign({ campaign_name: newRow.campaign_name, rowId: newRow.id });

        const selectedCampaignNames = newRow.campaign_name;
        const updatedCampaignIds = selectedCampaignNames.map((name: string) => {
          const campaign: any = campaignOptionsRef.current.find((opt: any) => opt.campaign_name === name);
          return campaign ? campaign.campaign_id : null;
        }).filter(Boolean);

        newRow.campaign_id = updatedCampaignIds;
      }

      if (countryChanged) {
        newRow.country_code = countryRef ? [countryRef?.current?.country_code] : [];
        newRow.home_language = countryRef ? countryRef?.current?.home_language : '';
      }

      // Vérification des duplications pour les changements
      if (keywordChanged || countryChanged || accountChanged) {
        const allPendingChanges = Array.from(pendingChanges.values());

        // Exclure la ligne actuelle des vérifications de duplications
        const potentialDuplicates = allPendingChanges.filter(
          (row: any) =>
            row.id !== newRow.id &&
            row.keyword === newRow.keyword &&
            row.country === newRow.country &&
            row.account === newRow.account
        );

        if (potentialDuplicates.length > 0) {
          isDuplicate = true;
          isDeleted = true;
        } else {
          // Effectuez l'appel API pour vérifier le statut de duplication
          try {
            const response: any = await activeModeExist({
              keywords: newRow.keyword,
              country: newRow.country[0],
              profile: newRow.account,
              platform: newRow.platform,
              workspaceId: workspace?.id,
            });
            isDuplicate = response.error?.data?.exists;
            isDeleted = response.error?.data?.exists;
          } catch (error) {
            console.warn("Erreur lors de l'appel API pour la vérification de duplication :", error);
            isDuplicate = true;
          }
        }
      }


      const updatedRow = { ...newRow, isDuplicate, isDeleted };

      // Mettre à jour les lignes et enregistrez les modifications
      setRows((prevRows: any) =>
        prevRows.map((row: any) => (row.id === newRow.id ? updatedRow : row))
      );

      if (isModified || isDuplicate) {
        setEditedRows((prev) => {
          const updated = new Map(prev);
          updated.set(newRow.id, updatedRow);
          handleSave(Array.from(updated.entries()).map(([id, changes]) => ({ id, changes })));
          onEdit(updated.size > 0);
          return updated;
        });
      } else {
        setEditedRows((prev) => {
          const updated = new Map(prev);
          updated.delete(newRow.id);
          handleSave(Array.from(updated.entries()).map(([id, changes]) => ({ id, changes })));
          onEdit(updated.size > 0);
          return updated;
        });
      }

      return updatedRow;
    },
    [initialRows, pendingChanges, activeModeExist, workspace, handleSave, onEdit]
  );



  // Récupérer keyword_id et keyword_status via getKeywordInfoKoBidding
  const { data: keywordInfo, isLoading, isFetching } = useGetKeywordInfoActiveModeQuery({
    client: workspace?.bqTable,
    keyword: rows.find((row: any) => row.id === selectedCampaign?.rowId)?.keyword,
    campaign: selectedCampaign?.campaign_name[0],
    profile: rows.find((row: any) => row.id === selectedCampaign?.rowId)?.account,
    country: rows.find((row: any) => row.id === selectedCampaign?.rowId)?.country
  },
    {
      skip: !selectedCampaign, // L'appel API est ignoré tant qu'il n'y a pas de campagne sélectionnée
    }
  );

  useEffect(() => {
    if (!isLoading && !isFetching && keywordInfo && selectedCampaign) {
      setRows((prevRows: any) =>
        prevRows.map((row: any) => {
          if (row.id === selectedCampaign.rowId) {
            return {
              ...row,
              keyword_id: keywordInfo[0]?.keyword_id ?? row.keyword_id,
            };
          }
          return row;
        })
      );

      setEditedRows((prev) => {
        const updated = new Map(prev);
        const existingChanges = updated.get(selectedCampaign.rowId) || {};

        const newChanges = {
          ...existingChanges,
          keyword_id: keywordInfo[0]?.keyword_id ?? existingChanges.keyword_id,
        };

        updated.set(selectedCampaign.rowId, newChanges);

        handleSave(
          Array.from(updated.entries()).map(([id, changes]) => ({ id, changes }))
        );

        return updated;
      });
    }
  }, [keywordInfo, selectedCampaign]);


  useEffect(() => {
    if (data) {
      setRows((prevRows: any) => {
        // Fusionner les nouvelles données avec l'état local
        const updatedRows = data.map((newRow: DataRow) => {
          const existingRow = prevRows.find((row: any) => row.id === newRow.id);

          if (existingRow) {
            // Conserver l'état local des lignes modifiées ou supprimées
            if (existingRow.isDeleted) {
              return { ...existingRow }; // Conserver toutes les valeurs modifiées de la ligne supprimée
            }
            if (editedRows.has(newRow.id)) {
              return { ...existingRow }; // Conserver toutes les modifications locales
            }
          }

          // Sinon, retourner la nouvelle ligne telle qu'elle est
          return newRow;
        });

        return updatedRows;
      });
    }
  }, [data, editedRows]);

  const handleDeleteItem = useCallback(
    (id: number | string) => {
      // Trouver la ligne à supprimer par ID
      const rowToDelete = rows.find((row: any) => row.id === id);

      if (rowToDelete) {
        // Mettre à jour uniquement la ligne spécifique à supprimer
        const updatedRows = rows.map((row: any) =>
          row.id === id ? { ...row, isDeleted: true } : row
        );

        // Mettre à jour l'état des lignes
        setRows(updatedRows);
        setDeletedRowIds((prev) => [...prev, id]);

        // Mettre à jour les lignes éditées
        setEditedRows((prev) => {
          const updated = new Map(prev);
          updated.set(id, { ...rowToDelete, isDeleted: true });
          handleSave(Array.from(updated.entries()).map(([id, changes]) => ({ id, changes })));
          onEdit(updated.size > 0);
          return updated;
        });
      }
    },
    [rows, handleSave, onEdit]
  );

  const handleResetItem = useCallback(
    (id: number | string) => {
      const originalRow = data.find((row) => row.id === id);
      if (originalRow) {
        setRows((prevRows: any) => prevRows.map((row: any) => (row.id === id ? originalRow : row)));
        setEditedRows((prev) => {
          const updated = new Map(prev);
          updated.delete(id);
          handleSave(Array.from(updated.entries()).map(([id, changes]) => ({ id, changes })));
          onEdit(updated.size > 0);
          return updated;
        });
      }
    },
    [data, handleSave, onEdit]
  );

  const handleReset = useCallback(() => {
    setRows([...data]);
    setEditedRows(new Map());
    setSelectionModel([]);
    setDeletedRowIds([]);
    onEdit(false);
  }, [data, onEdit]);

  useEffect(() => {
    if (onReset) {
      handleReset();
    }
  }, [onReset, handleReset]);

  useEffect(() => {
    if (!onApply) {
      setEditedRows(new Map());
    }
  }, [onApply]);

  const NoRowsOverlay = () => (
    <Box sx={{ p: 2, textAlign: 'center' }}>
      <Typography variant="h6" color="textSecondary">
        No results found
      </Typography>
    </Box>
  );

  // Get class names for rows and cells
  const getRowClassName = (params: any) => {
    const classes: string[] = [];
    if (params.row.isDeleted) {
      classes.push('deleted');
    } else if (params.row.status === false) {
      classes.push('status-false');
    }
    if (params.row.isDuplicate) {
      classes.push('duplicate'); // Add class for duplicates
    }
    if (editedRows.has(params.id)) {
      classes.push('edited');
    }
    return classes.join(' ');
  };

  const getCellClassName = (params: any) => {
    if (params.row.status === false && ['keyword', 'country', 'frequency', 'device', 'account', 'device_gads', 'campaign_name', 'status'].includes(params.field)) {
      return 'cell-opacity';
    }
    return '';
  };

  return (
    <Box
      sx={{
        '& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-cell:focus': {
          outline: 'none !important',
        },
        '& .MuiDataGrid-row.edited': {
          backgroundColor: '#666546',
          '&:hover': {
            backgroundColor: '#666546 !important',
          },
        },
        '& .MuiDataGrid-row.deleted': {
          backgroundColor: '#4c3333',
          '&:hover': {
            backgroundColor: '#4c3333 !important',
          },
        },
        '& .MuiDataGrid-row.edited.status-false': {
          backgroundColor: '#666546', /* Override with edited color */
        },
        '& .MuiDataGrid-row.duplicate': {
          backgroundColor: '#4c3333!important', /* Background color for duplicate rows */
          '&:hover': {
            backgroundColor: '#4c3333 !important',
          },
        },
        '& .MuiDataGrid-cell.cell-opacity': {
          opacity: 0.3,
        },
      }}
    >
      <DataGridPro
        disableRowSelectionOnClick
        onProcessRowUpdateError={handleProcessRowUpdateError} // Ajoutez cette ligne
        rows={rows}
        columnVisibilityModel={columnVisibilityModel}
        pagination
        paginationModel={paginationModel}
        columns={createColumns(handleDeleteItem, handleResetItem, editedRows, campaignOptionsRef, accountOptionsRef, rows, countryRef, setEditedRows, currentLang, setDeletedRowIds, setRows)}
        onColumnVisibilityModelChange={handleChangeColumnVisibilityModel}
        onPaginationModelChange={handlePaginationModelChange}
        disableDensitySelector
        onRowSelectionModelChange={(newSelectionModel) => setSelectionModel(newSelectionModel as (number | string)[])}
        processRowUpdate={processRowUpdate}
        getRowClassName={getRowClassName}
        getCellClassName={getCellClassName}
        slots={{
          noRowsOverlay: NoRowsOverlay,
          toolbar: GridToolbar

        }}
      />
    </Box>
  );
}