import { useTheme } from '@mui/material/styles';
import { Container, Paper, Box, Typography, Skeleton, Tooltip } from '@mui/material';
import { Carousel, CarouselArrowNumberButtons } from 'src/components/carousel-serp';
import { useCarousel } from 'src/hooks/use-carousel';
import { useGetMinDateSerpQuery, useGetSerpHistoryQuery } from 'src/context/api/serpHistory/api';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setFilterMinDatePicker } from 'src/context/reducers/filter-settings/filterSlice';
import { useLocales } from 'src/locales';

export default function HistorySerp() {
  const theme = useTheme();
  const { data: serpHistoryData } = useGetSerpHistoryQuery();
  const { data: minDatePicker } = useGetMinDateSerpQuery();
  const [iframeSrcList, setIframeSrcList]: any = useState<string[]>([]);
  const [thumbsSrcList, setThumbsSrcList] = useState<string[]>([]);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const dispatch = useDispatch();
  const { t, currentLang } = useLocales();

  useEffect(() => {
    if (minDatePicker) {
      dispatch(setFilterMinDatePicker(minDatePicker[0]?.first_date));
    }
  }, [minDatePicker]);

  useEffect(() => {
    if (serpHistoryData && Array.isArray(serpHistoryData) && serpHistoryData.length > 0) {
      const newIframes = serpHistoryData.map((item: any) => {
        const tempDiv = document.createElement("div");
        tempDiv.innerHTML = item.html;

        tempDiv.querySelectorAll(".cTcgmb").forEach((el: any) => (el.style.display = "none"));

        const popup = tempDiv.querySelector('div[aria-modal="true"]');
        if (popup) popup.remove();

        tempDiv.querySelectorAll("a").forEach((el: HTMLAnchorElement) => {
          el.removeAttribute("onClick");
          el.setAttribute("target", "_blank");
          const ping = el.getAttribute("ping");
          if (ping) {
            const match = ping.match(/url=([^&]+)/);
            if (match) {
              el.setAttribute("href", decodeURIComponent(match[1]));
            }
          }
        });

        const blob = new Blob([tempDiv.innerHTML], { type: "text/html" });
        return URL.createObjectURL(blob);
      });

      setIframeSrcList(newIframes);
      setThumbsSrcList(newIframes);

      return () => newIframes.forEach((url) => URL.revokeObjectURL(url));
    }
  }, [serpHistoryData]);



  const carousel: any = useCarousel({ slides: iframeSrcList });


  useEffect(() => {
    if (carousel.mainApi && iframeSrcList.length > 0) {
      const lastIndex = iframeSrcList.length - 1;
      setSelectedIndex(lastIndex);
      carousel.mainApi.scrollTo(lastIndex, true);
    }
  }, [carousel.mainApi, iframeSrcList]);


  useEffect(() => {
    if (carousel.mainApi) {
      carousel.mainApi.on("select", () => {
        setSelectedIndex(carousel.mainApi.selectedScrollSnap());
      });
    }
  }, [carousel.mainApi]);

  const handleThumbClick = (index: number) => {
    if (carousel.mainApi) {
      carousel.mainApi.scrollTo(index);
      setSelectedIndex(index);
    }
  };

  const thumbsContainerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (thumbsContainerRef.current) {
      const selectedThumb = thumbsContainerRef.current.children[selectedIndex];
      if (selectedThumb) {
        selectedThumb.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
      }
    }
  }, [selectedIndex]);


  const isLoading = !serpHistoryData || iframeSrcList.length === 0;

  return (
    <Box sx={{ px: 3 }}>
      <Paper
        sx={{
          backgroundColor: theme.palette.mode === 'dark' ? '#212b36' : 'white',
          boxShadow: '0px 0px 2px 0px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12)',
          borderRadius: 2,
          mt: 2,
          p: 2
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box sx={{ pt: 2, px: 2, mb: 3 }}>
            <Typography variant="overline" color="textSecondary" sx={{ mb: 1 }}>
              Competitive Intelligence
            </Typography>
            <Typography variant="h6" sx={{ mb: 1 }}>
              Brand Wayback
            </Typography>
          </Box>
        </Box>

        <Box sx={{ backgroundColor: '#28323C', borderRadius: 2, py: 2, mb: .8 }}>
          <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', position: 'relative' }}>
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                pointerEvents: 'none',
                zIndex: 1
              }}
            />

            <Box
              ref={thumbsContainerRef}
              sx={{
                mb: 2,
                display: 'flex',
                justifyContent: 'flex-start',
                overflowX: 'auto',
                maxWidth: '45vw',
                height: '170px',
                scrollbarWidth: 'none',
                '&::-webkit-scrollbar': { display: 'none' },
                whiteSpace: 'nowrap',
                scrollSnapType: 'x mandatory',
                position: 'relative',
                maskImage: !isLoading
                  ? selectedIndex === 0
                    ? 'linear-gradient(to right, white 90%, rgba(255,255,255,0.3))'
                    : selectedIndex === thumbsSrcList.length - 1
                      ? 'linear-gradient(to right, rgba(255,255,255,0.3), white 90%)'
                      : 'linear-gradient(to right, rgba(255,255,255,0.1), white 25%, white 75%, rgba(255,255,255,0.1))'
                  : 'none',

                WebkitMaskImage: !isLoading
                  ? selectedIndex === 0
                    ? 'linear-gradient(to right, white 90%, rgba(255,255,255,0.3))'
                    : selectedIndex === thumbsSrcList.length - 1
                      ? 'linear-gradient(to right, rgba(255,255,255,0.3), white 90%)'
                      : 'linear-gradient(to right, rgba(255,255,255,0.1), white 25%, white 75%, rgba(255,255,255,0.1))'
                  : 'none',
              }}
            >
              {isLoading
                ?
                Array.from(new Array(5)).map((_, index) => (
                  <Box
                    key={index}
                    sx={{
                      display: 'inline-block',
                      width: '220px',
                      height: '150px',
                      borderRadius: 1,
                      margin: '7px',
                      marginRight: index === 4 ? 0 : '8px',
                    }}
                  >
                    <Skeleton
                      variant="rectangular"
                      width="100%"
                      height="100%"
                      style={{ borderRadius: 10 }}
                      animation="wave"
                    />
                  </Box>
                ))
                : thumbsSrcList.map((thumbSrc, index) => {
                  const distance = Math.abs(selectedIndex - index);
                  const opacity = Math.max(0.25, 1 - distance * 0.25);
                  console.log(serpHistoryData[index], "test")
                  return (
                    <Tooltip title={serpHistoryData[index]?.date &&
                      new Intl.DateTimeFormat(currentLang?.value === "fr" ? "fr-FR" : "en-US", {
                        day: "2-digit",
                        month: "short",
                        year: "numeric",
                      }).format(new Date(serpHistoryData[index]?.date))} arrow>
                      <Box
                        key={index}
                        sx={{
                          display: 'inline-block',
                          width: '220px',
                          height: '150px',
                          margin: '7px',
                          cursor: 'pointer',
                          position: 'relative',
                          overflow: 'hidden',
                          borderRadius: 1,
                          boxShadow: index === selectedIndex ? 'rgba(223, 48, 242, 0.48) 4px 4px 8px 0px' : '',
                          border: index === selectedIndex ? '4px solid #8E33FF' : '2px solid transparent',
                          flexShrink: 0,
                          marginRight: index === thumbsSrcList.length - 1 ? 0 : '8px',
                          transition: 'opacity 0.5s ease-in-out',
                          opacity: opacity,
                        }}
                        onClick={() => handleThumbClick(index)}
                      >
                        <iframe
                          src={thumbSrc}
                          scrolling="no"
                          style={{
                            width: '400%',
                            height: '400%',
                            transform: 'scale(0.25)',
                            transformOrigin: 'top left',
                            pointerEvents: 'none',
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            border: 'none',
                            overflow: 'hidden',
                          }}
                        />

                      </Box>
                    </Tooltip>
                  );
                })}
            </Box>
          </Box>

          <Box sx={{ height: "880px", px: 3, position: 'relative', borderRadius: '15px' }}>
            {isLoading ? (
              <Skeleton
                variant="rectangular"
                width="100%"
                height="870px"
                style={{ borderRadius: 10 }}
                animation="wave"
              />
            ) : (
              <Carousel carousel={carousel} sx={{ overflow: 'hidden', borderRadius: '15px' }}>
                {iframeSrcList.map((iframeSrc: any, index: any) => (
                  <Box key={index} sx={{ borderRadius: '15px' }}>
                    {iframeSrc && (
                      <iframe
                        src={iframeSrc}
                        style={{ width: '100%', height: '870px', border: '1px solid transparent', borderRadius: '15px' }}
                      />
                    )}
                  </Box>
                ))}
              </Carousel>
            )}

            {!isLoading && iframeSrcList.length !== 0 && (
              <CarouselArrowNumberButtons
                {...carousel.arrows}
                totalSlides={iframeSrcList.length}
                selectedIndex={selectedIndex + 1}
                currentDate={serpHistoryData}
                sx={{
                  position: 'absolute',
                  top: 30,
                  left: '50%',
                  transform: 'translateX(-50%)',
                  border: '1px solid',
                  borderRadius: '40px',
                  backgroundColor: 'rgba(0, 0, 0, 0.6)',
                  color: 'white',
                  padding: '5px 15px',
                  opacity: .75
                }}
              />

            )}
          </Box>
        </Box>

      </Paper>
    </Box>
  );
}
