import { createApi } from "@reduxjs/toolkit/query/react";
import fetchBaseQueryCSRF from "../helper";

const serpHistory = createApi({
    baseQuery: fetchBaseQueryCSRF({ baseUrl: CROSS_API_URL }),
    reducerPath: 'api.serpHistory',
    tagTypes: ['serpHistory'],
    endpoints: (builder) => ({
      getSerpHistory: builder.query<any, void>({
        query: () => ({
          url: `/serpHistory/`,
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Cache-Control': 'max-age=0',
          },
        }),
      }),
      getMinDateSerp: builder.query<any, void>({
        query: () => ({
          url: `/serpHistory/getMinDateSerp`,
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Cache-Control': 'max-age=0',
          },
        }),
      }),
    }),
  });
  
  export const {
    useGetSerpHistoryQuery,
    useGetMinDateSerpQuery
  } = serpHistory;
  
  export default serpHistory;
  