// src/components/carousel-serp/hooks/use-carousel.ts

import { useState } from 'react';
import useEmblaCarousel from 'embla-carousel-react';
import type { EmblaCarouselType, EmblaOptionsType } from 'embla-carousel';
import type { UseEmblaCarouselType } from 'embla-carousel-react';

// On importe les types du même dossier (ou ajustez le chemin selon votre arborescence)
import type {
  UseCarouselReturn,
  CarouselOptions,
} from '../components/carousel-serp/types';

/* ----------------------------------------------------------------------
   Exemple d'items (slides) 
   Vous pouvez changer selon votre data réelle 
   ou accepter un paramètre "slides" si besoin.
---------------------------------------------------------------------- */
interface SlideItem {
  id: string;
  coverUrl: string;
}

/* 
   Si vous souhaitez un paramètre "options" ou "slides" 
   dans votre hook, définissez-le ici
*/
interface UseCarouselProps {
  slides?: SlideItem[];
  options?: Partial<CarouselOptions>;
}

/* ----------------------------------------------------------------------
   Hook useCarousel
   -> Renvoie un objet conforme à UseCarouselReturn
---------------------------------------------------------------------- */
export function useCarousel(props?: UseCarouselProps): UseCarouselReturn {
  // On peut extraire "slides" de props (par ex.), sinon c'est un tableau par défaut
  const slides = props?.slides ?? [];

  // On peut prendre vos "options" (ex. si vous voulez configurer Embla)
  const carouselOptions: any = (props?.options ?? {}) as unknown as EmblaOptionsType;

  /* 
     1) Carrousel principal 
        embla-carousel-react renvoie [refCallback, api]
  */
  const [mainRefCb, mainApiRaw] = useEmblaCarousel(carouselOptions);

  // On cast la ref callback 
  const mainRef = mainRefCb as unknown as UseEmblaCarouselType[0];
  // On cast l'API Embla
  const mainApi = mainApiRaw as unknown as EmblaCarouselType | undefined;

  /* 
     2) Carrousel "thumbs" 
        -> pour les miniatures
  */
  const [thumbsRefCb, thumbsApiRaw] = useEmblaCarousel(carouselOptions);

  // On cast la ref callback 
  const thumbsRef = thumbsRefCb as unknown as UseEmblaCarouselType[0];
  // On cast l'API Embla
  const thumbsApi = thumbsApiRaw as unknown as EmblaCarouselType | undefined;

  /* 
     3) State local pour l'index sélectionné 
        -> exemple : dots, thumbs, etc. 
  */
  const [selectedIndex, setSelectedIndex] = useState(0);

  /* 
     4) Arrows 
  */
  const onClickPrev = () => {
    if (mainApi) {
      mainApi.scrollPrev();
      setSelectedIndex(mainApi.selectedScrollSnap());
    }
  };
  const onClickNext = () => {
    if (mainApi) {
      mainApi.scrollNext();
      setSelectedIndex(mainApi.selectedScrollSnap());
    }
  };

  /* 
     5) Dots 
        -> On récupère la liste des positions de scrollSnaps 
  */
  const scrollSnaps = mainApi?.scrollSnapList() ?? [];
  const dotCount = scrollSnaps.length;

  const onClickDot = (index: number) => {
    if (mainApi) {
      mainApi.scrollTo(index);
      setSelectedIndex(mainApi.selectedScrollSnap());
    }
  };

  /* 
     6) Thumbs 
  */
  const onClickThumb = (index: number) => {
    if (mainApi) {
      mainApi.scrollTo(index);
      setSelectedIndex(mainApi.selectedScrollSnap());
    }
  };

  /*
     7) Autoplay
        -> On fait un "fake" isPlaying + callbacks 
        -> Si vous avez une vraie logique d'autoplay, implémentez-la 
  */
  const autoplay = {
    isPlaying: false,
    onTogglePlay: () => {
      // ...
    },
    onClickAutoplay: (callback: () => void) => {
      callback();
    },
  };

  /*
     8) Progress
        -> Valeur "fake" 
        -> Si vous voulez un vrai calcul, basez-vous sur mainApi
  */
  const progress = {
    value: 0,
  };

  /*
     9) AutoScroll
        -> Même type que autoplay
  */
  const autoScroll = {
    isPlaying: false,
    onTogglePlay: () => {
      // ...
    },
    onClickAutoplay: (callback: () => void) => {
      callback();
    },
  };

  /*
     10) Options 
        -> On peut renvoyer vos options 
        -> pluginNames? si besoin
  */
  const mergedOptions: CarouselOptions = {
    ...props?.options,
  } as CarouselOptions;

  /* 
     11) Retour complet d'un objet "UseCarouselReturn"
        -> On remplit TOUTES les propriétés 
           (arrows, dots, thumbs, autoplay, progress, autoScroll, etc.)
  */
  const result: UseCarouselReturn = {
    // Facultatif
    pluginNames: [],

    // Les options
    options: mergedOptions,

    // Principal
    mainRef,
    mainApi,

    // Thumbs
    thumbs: {
      thumbsApi,
      thumbsRef,
      selectedIndex,
      onClickThumb,
    },

    // Dots
    dots: {
      dotCount,
      selectedIndex,
      scrollSnaps,
      onClickDot,
    },

    // Progress
    progress,

    // Arrows
    arrows: {
      disablePrev: mainApi ? !mainApi.canScrollPrev() : true,
      disableNext: mainApi ? !mainApi.canScrollNext() : true,
      onClickPrev,
      onClickNext,
    },
  };

  return result;
}
