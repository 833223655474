import { useEffect } from 'react';
// @mui
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Drawer from '@mui/material/Drawer';
// hooks
import { useResponsive } from 'src/hooks/use-responsive';
import { useMockedUser } from 'src/hooks/use-mocked-user';
// components
import Logo from 'src/components/logo';
import Scrollbar from 'src/components/scrollbar';
import { usePathname, useRouter } from 'src/routes/hooks';
import { NavSectionVertical } from 'src/components/nav-section';
//
import { NAV } from '../config-layout';
import { useNavData } from './config-navigation';
import { Autocomplete, ListItemText, MenuItem, TextField, useTheme } from "@mui/material";
import { NavToggleButton } from '../_common';
import { useGetWorkspacesQuery, useInvitationsQuery } from 'src/context/api/workspaces';
import { setCurrentWorkspace, useCurrentWorkspace } from 'src/context/reducers/app-settings';
import { storeSelectedAdAccount, storeSelectedAds, storeSelectedAdsets, storeSelectedCampaigns } from 'src/context/reducers/attribution-settings';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { setFilterAdvertizerPLA, setFilterAveragePositionPLA, setFilterBrandPLA, setFilterCountry, setFilterDomainPLA, setFilterImpressionSharePLA, setFilterKeyword, setFilterLoadedKeyword, setFilterPricingRangePLA, setFilterRatingRangePLA, setNotificationBrandInNonBrandCampaigns, setNotificationBrandInNonBrandCampaignsFilterEnabled, setNotificationBrandInfringement, setNotificationBrandInfringementNotification, setNotificationBrandInfringementNotificationTXT, setNotificationBrandInfringementTXT } from 'src/context/reducers/filter-settings/filterSlice';

// ----------------------------------------------------------------------

type Props = {
  openNav: boolean;
  onCloseNav: VoidFunction;
};

export default function NavVertical({ openNav, onCloseNav }: Props) {
  const { user } = useMockedUser();
  const theme = useTheme();
  const pathname = usePathname();
  const lgUp = useResponsive('up', 'lg');
  const navData = useNavData();
  const router = useRouter();
  const workspaces: any = useGetWorkspacesQuery(null);
  const workspace: any = useCurrentWorkspace();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
  }, [pathname]);

  const handleChangeWorkspace = (item: any) => {
    setSearchParams({ workspace: item.id.toString() });
    dispatch(storeSelectedAdAccount(null as any));
    dispatch(storeSelectedCampaigns([]));
    dispatch(storeSelectedAdsets([]));
    dispatch(storeSelectedAds([]));
    dispatch(setFilterKeyword([]));
    dispatch(setFilterCountry([]));
    dispatch(setFilterBrandPLA([]));
    dispatch(setFilterDomainPLA([]));
    dispatch(setFilterAdvertizerPLA([]));
    dispatch(setNotificationBrandInfringementNotification([]))
    dispatch(setNotificationBrandInfringementNotificationTXT([]))
    dispatch(setNotificationBrandInNonBrandCampaignsFilterEnabled(false))
    dispatch(setNotificationBrandInNonBrandCampaigns(false))
    dispatch(setFilterRatingRangePLA([0, 5]));
    dispatch(setFilterPricingRangePLA([0, 50000]));
    dispatch(setFilterLoadedKeyword([]));
    dispatch(setFilterImpressionSharePLA([0, 100]))
    dispatch(setFilterAveragePositionPLA([0, 50]))
    dispatch(setNotificationBrandInfringement(false))
    dispatch(setNotificationBrandInfringementTXT(false))

    dispatch(setCurrentWorkspace(item));

    if (item.isDataReady === true) {
      router.push("/dash/brand-overview?workspace=" + item.id.toString());
    } else {
      router.push("/data-not-available?workspace=" + item.id.toString());
    }
  };

  useEffect(() => {
    if (workspace === null && !searchParams.has('workspace') && (workspaces?.data && !window.location.href.includes("/dash") || window.location.href.includes("/admin"))) {
      setSearchParams({ workspace: workspaces?.data[0].id.toString() });
      dispatch(storeSelectedAdAccount(null as any));
      dispatch(storeSelectedCampaigns([]));
      dispatch(storeSelectedAdsets([]));
      dispatch(storeSelectedAds([]));
      dispatch(setCurrentWorkspace(workspaces.data[0]));

    }

  }, [workspace, workspaces?.data, searchParams, dispatch, setSearchParams]);

  const sortedWorkspaces = workspaces?.data ? [...workspaces.data].sort((a, b) => a.name.localeCompare(b.name)) : [];

  const invitations: any = useInvitationsQuery(null);

  const handleLogoClick = () => {
    const invitationsLength = invitations?.currentData?.length || 0;
    const sortedWorkspacesLength = sortedWorkspaces?.length || 0;

    if (invitationsLength > 1 && sortedWorkspacesLength >= 1) {
      router.push("/workspaces");
    } else if (sortedWorkspacesLength === 1 && (invitationsLength === 0 || invitationsLength === null)) {
      router.push(`/dash/brand-overview?workspace=${workspace?.id}`);
    } else {

    }
  };

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        backgroundColor: theme.palette.mode === "dark" ? '#1c252e' : '#141a21',
        '& .simplebar-content': {
          height: 1,
          display: 'flex',
          flexDirection: 'column',
        },
      }}
    >
      <div onClick={handleLogoClick}>
        <Logo sx={{ mt: 3, ml: 3, mb: 5 }} />
      </div>

      <Autocomplete
        sx={{
          pl: 2,
          pr: 2,
          mb: 2,
          '& .MuiInputBase-root': {
            color: theme.palette.mode === 'dark' ? '#fff' : '#fff',
          },
        }}
        options={sortedWorkspaces}
        getOptionLabel={(option: any) => option?.name}
        value={workspace}
        onChange={(event, selectedOptions) => handleChangeWorkspace(selectedOptions)}
        disableClearable
        renderInput={(params) => (
          <TextField
            {...params}
            label="Workspaces"
            margin="none"
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <Box sx={{ p: 1 }}>
                  <Box component="img" src={workspace?.name.includes('Demo') ? '/assets/logo.png' : `https://www.google.com/s2/favicons?domain=${workspace?.url}&sz=64`} sx={{ width: 22, height: 22, mr: 1, ml: 1, borderRadius: '5px' }} />
                  {params.InputProps.startAdornment}
                </Box>
              ),
            }}
          />
        )}
        renderOption={(props, option) => (
          <MenuItem {...props} key={option.id}>
            <Box component="img" src={option?.name.includes('Demo') ? '/assets/logo.png' : `https://www.google.com/s2/favicons?domain=${option?.url}&sz=64`} sx={{ width: 22, height: 22, borderRadius: '5px' }} />
            <ListItemText sx={{ marginLeft: 2 }} primary={option?.name} />
          </MenuItem>
        )}
      />

      <NavSectionVertical
        data={navData}
        config={{
          currentRole: user?.role || 'admin',
        }}
      />

      <Box sx={{ flexGrow: 1 }} />
    </Scrollbar>
  );

  return (
    <Box
      component="nav"
      sx={{
        backgroundColor: theme.palette.mode === "dark" ? '#1c252e' : '#141a21',
        flexShrink: { lg: 0 },
        width: { lg: NAV.W_VERTICAL },
      }}
    >
      <NavToggleButton />

      {lgUp ? (
        <Stack
          sx={{
            height: 1,
            position: 'fixed',
            width: NAV.W_VERTICAL,
            borderRight: (theme) => `dashed 1px ${theme.palette.divider}`,
          }}
        >
          {renderContent}
        </Stack>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          PaperProps={{
            sx: {
              width: NAV.W_VERTICAL,
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
}