import { Helmet } from 'react-helmet-async';
import { useLocales } from 'src/locales';
import BrandWayback from 'src/sections/overview/dashboards/views/brandWayback';

// ----------------------------------------------------------------------

export default function WaybackBrand() {
  const { t } = useLocales()
  return (
    <>
      <Helmet>
        <title>Brand Wayback</title>
      </Helmet>

      <BrandWayback />
    </>
  );
}
