import { useState, useCallback, useEffect } from 'react';
import {
  DataGridPro,
  GridColDef,
  GridToolbar,
  GridColumnVisibilityModel,
} from '@mui/x-data-grid-pro';
import { Stack, useTheme } from '@mui/system';
import { Box, LinearProgress, Typography } from '@mui/material';
import { fPercent } from 'src/utils/format-number';
import { setFilterDate } from 'src/context/reducers/filter-settings/filterSlice';
import { useDispatch, useSelector } from 'react-redux';
import { format, parse } from 'date-fns';
import { enUS, fr } from 'date-fns/locale';
import { useLocales } from 'src/locales';
import { useCurrentWorkspace } from 'src/context/reducers/app-settings';
import { formatDate } from 'src/utils/dates-labels';
import { formatCurrency } from 'src/utils/format-currency';
import { has } from 'lodash';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

type Props = {
  data: any[];
  visibilityFlags?: {
    has_ad_cost_shopping?: boolean;
    has_ad_cost_pmax?: boolean;
    has_ad_cost_search?: boolean;
    has_ctr_shopping?: boolean;
    has_ctr_pmax?: boolean;
    has_ctr_search?: boolean;
  };
};

export default function DataGridCustomDay({ data }: Props) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const filterStore = useSelector((state: any) => state.filters);
  const { t, currentLang } = useLocales();
  const workspace = useCurrentWorkspace();
  const isAdvancedView = useSelector((state: any) => state.filters.isAdvancedView);

  const createColumns = (theme: any, t: any, lgUp: any, formatCurrency: any, currency: any, locale: any, formatDate: any): GridColDef[] => [
    {
      field: 'date',
      headerName: t('dashboard.global.date'),
      flex: 1,
      minWidth: 200,
      valueGetter: (params: any) => {
        if (filterStore.timestep === 'date') {
          // const dateObj = new Date(Date.UTC(params));
          const localDate = new Date(params); // En local (selon le fuseau horaire de l'utilisateur)
          // Convertir en UTC
          const utcDate = new Date(localDate.getTime() + localDate.getTimezoneOffset() * 60000);
          // if (dateObj.getDate()) {
          if (params) {
            const date = currentLang.value === 'fr' ? format(utcDate, "dd/MM/yyyy", { locale: fr }) : format(utcDate, "MM/dd/yyyy", { locale: enUS })

            return date
          }

        } else if (filterStore.timestep === 'year_week') {
          return params
        }
        else {
          const [year, week] = params.split('-');
          return `${week}/${year}`
          // return params
        }
      },
    },
    {
      field: 'brandSearches',
      headerName: t('dashboard.brandTraffic.brandSearches'),
      flex: 1,
      minWidth: 150,
      type: 'number',
      renderCell: (params) => (
        <span style={{ color: theme.palette.mode === "dark" ? '#db8377' : '#B71D18' }}>
          {params.row.brandSearches}
        </span>
      ),
    },
    {
      field: 'totalBrandTraffic',
      headerName: t('dashboard.brandTraffic.totalBrandTraffic'),
      flex: 1,
      minWidth: 150,
      renderCell: (params) => (
        <span style={{ color: theme.palette.mode === "dark" ? '#a64b7b' : '#880E4F' }}>
          {params.row.totalBrandTraffic}
        </span>
      ),
      type: 'number',
    },
    {
      field: 'organicBrandTraffic',
      headerName: t('dashboard.brandTraffic.organicBrandTraffic'),
      flex: 1,
      minWidth: 150,
      type: 'number',
      renderCell: (params) => (
        <span style={{ color: theme.palette.mode === "dark" ? '#6dad8d' : '#065E49' }}>
          {params.row.organicBrandTraffic}
        </span>
      ),
    },
    {
      field: 'paidBrandTraffic',
      headerName: t('dashboard.brandTraffic.paidBrandTraffic'),
      flex: 1,
      minWidth: 150,
      type: 'number',
      renderCell: (params) => (
        <span style={{ color: theme.palette.mode === "dark" ? '#65b5c9' : '#006C9C' }}>
          {params.row.paidBrandTraffic}
        </span>
      ),
    },
    {
      field: 'uncapturedBrandTraffic',
      headerName: t('dashboard.brandTraffic.uncapturedBrandTraffic'),
      flex: 1,
      minWidth: 150,
      type: 'number',
      renderCell: (params) => (
        <span style={{ color: theme.palette.mode === "dark" ? '#b7b7b7' : '#B7B7B7' }}>
          {params.row.paidBrandTraffic}
        </span>
      ),
    },
    {
      field: 'blendedCtr',
      headerName: t('dashboard.global.blended_ctr'),
      flex: 1,
      width: 160,
      minWidth: 150,
      renderCell: (params) => {
        const cappedBlendedCtr = Math.min(params.row.blendedCtr, 100); // Limiter à 100%
        return (
          <Stack spacing={1} direction="row" alignItems="center" sx={{ px: 1, width: 1, height: 1 }}>
            <LinearProgress
              value={cappedBlendedCtr}
              variant="determinate"
              sx={{
                width: 1,
                height: 6,
                backgroundColor: theme.palette.mode === "dark" ? '#3d1873' : 'rgb(61, 24, 115, .24)',
                '& .MuiLinearProgress-bar': {
                  backgroundColor: '#7635dc',
                }
              }}
            />
            <Typography variant="caption" sx={{ width: 80 }}>
              {cappedBlendedCtr === 0 ? '0%' : fPercent(cappedBlendedCtr)}
            </Typography>
          </Stack>
        );
      },
      type: 'number',
    },
    {
      field: 'paidCtr',
      headerName: t('dashboard.brandTraffic.paidCtr'),
      flex: 1,
      width: 160,
      minWidth: 150,
      renderCell: (params) => (
        <Stack spacing={1} direction="row" alignItems="center" sx={{ px: 1, width: 1, height: 1 }}>
          <LinearProgress
            value={params.row.paidCtr}
            variant="determinate"
            sx={{
              width: 1,
              height: 6,
              backgroundColor: theme.palette.mode === "dark" ? '#183c50' : 'rgb(24, 60, 80, .24)',
              '& .MuiLinearProgress-bar': {
                backgroundColor: '#006C9C',
              }
            }}
          />
          <Typography variant="caption" sx={{ width: 80 }}>
            {params.row.paidCtr === 0 ? '0%' : fPercent(params.row.paidCtr)}
          </Typography>
        </Stack>
      ),
      type: 'number',
    },

    // SHOPPING CTR
    {
      field: 'shoppingCtr',
      headerName: t('dashboard.brandTraffic.shoppingCtr') + ` (${formatDate(filterStore?.start)} - ${formatDate(filterStore?.end)})`,
      width: 200, minWidth: filterStore?.compare ? 245 : 200, maxWidth: 225,
      renderHeader: () => (
        <div style={{ whiteSpace: 'pre-line', display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
          <span style={{ textAlign: 'right' }}>{t('dashboard.brandTraffic.shoppingCtr')}</span>
          {filterStore?.compare && (
            <span style={{ textAlign: 'left' }}>
              {formatDate(filterStore?.start)} - {formatDate(filterStore?.end)}
            </span>
          )}
        </div>
      ),
      flex: lgUp ? 1 : undefined,
      renderCell: (params) => (
        <Stack spacing={1} direction="row" alignItems="center" sx={{ px: 1, width: 1, height: 1 }}>
          <LinearProgress
            value={params.row.shoppingCtr}
            variant="determinate"
            sx={{
              width: 1,
              height: 6,
              backgroundColor: theme.palette.mode === "dark" ? '#183c50' : 'rgb(24, 60, 80, .24)',
              '& .MuiLinearProgress-bar': { backgroundColor: '#006C9C' },
            }}
          />
          <Typography variant="caption" sx={{ width: 80 }}>
            {params.row.shoppingCtr === 0 ? '0%' : fPercent(params.row.shoppingCtr)}
          </Typography>
        </Stack>
      ),
      type: 'number',
    },

    // PMAX CTR
    {
      field: 'pmaxCtr',
      headerName: t('dashboard.brandTraffic.pmaxCtr') + ` (${formatDate(filterStore?.start)} - ${formatDate(filterStore?.end)})`,
      width: 200, minWidth: filterStore?.compare ? 245 : 200, maxWidth: 225,
      renderHeader: () => (
        <div style={{ whiteSpace: 'pre-line', display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
          <span style={{ textAlign: 'right' }}>{t('dashboard.brandTraffic.pmaxCtr')}</span>
          {filterStore?.compare && (
            <span style={{ textAlign: 'left' }}>
              {formatDate(filterStore?.start)} - {formatDate(filterStore?.end)}
            </span>
          )}
        </div>
      ),
      flex: lgUp ? 1 : undefined,
      renderCell: (params) => (
        <Stack spacing={1} direction="row" alignItems="center" sx={{ px: 1, width: 1, height: 1 }}>
          <LinearProgress
            value={params.row.pmaxCtr}
            variant="determinate"
            sx={{
              width: 1,
              height: 6,
              backgroundColor: theme.palette.mode === "dark" ? '#183c50' : 'rgb(24, 60, 80, .24)',
              '& .MuiLinearProgress-bar': { backgroundColor: '#006C9C' },
            }}
          />
          <Typography variant="caption" sx={{ width: 80 }}>
            {params.row.pmaxCtr === 0 ? '0%' : fPercent(params.row.pmaxCtr)}
          </Typography>
        </Stack>
      ),
      type: 'number',
    },

    // SEARCH CTR
    {
      field: 'searchCtr',
      headerName: t('dashboard.brandTraffic.searchCtr') + ` (${formatDate(filterStore?.start)} - ${formatDate(filterStore?.end)})`,
      width: 200, minWidth: filterStore?.compare ? 245 : 200, maxWidth: 225,
      renderHeader: () => (
        <div style={{ whiteSpace: 'pre-line', display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
          <span style={{ textAlign: 'right' }}>{t('dashboard.brandTraffic.searchCtr')}</span>
          {filterStore?.compare && (
            <span style={{ textAlign: 'left' }}>
              {formatDate(filterStore?.start)} - {formatDate(filterStore?.end)}
            </span>
          )}
        </div>
      ),
      flex: lgUp ? 1 : undefined,
      renderCell: (params) => (
        <Stack spacing={1} direction="row" alignItems="center" sx={{ px: 1, width: 1, height: 1 }}>
          <LinearProgress
            value={params.row.searchCtr}
            variant="determinate"
            sx={{
              width: 1,
              height: 6,
              backgroundColor: theme.palette.mode === "dark" ? '#183c50' : 'rgb(24, 60, 80, .24)',
              '& .MuiLinearProgress-bar': { backgroundColor: '#006C9C' },
            }}
          />
          <Typography variant="caption" sx={{ width: 80 }}>
            {params.row.searchCtr === 0 ? '0%' : fPercent(params.row.searchCtr)}
          </Typography>
        </Stack>
      ),
      type: 'number',
    },


    {
      field: 'organicCtr',
      headerName: t('dashboard.brandTraffic.organicCtr'),
      flex: 1,
      width: 160,
      minWidth: 150,
      renderCell: (params) => (
        <Stack spacing={1} direction="row" alignItems="center" sx={{ px: 1, width: 1, height: 1 }}>
          <LinearProgress
            value={params.row.organicCtr}
            variant="determinate"
            sx={{
              width: 1,
              height: 6,
              backgroundColor: theme.palette.mode === "dark" ? '#19383a' : 'rgb(25, 56, 58, .24)',
              '& .MuiLinearProgress-bar': {
                backgroundColor: '#065E49',
              }
            }}
          />
          <Typography variant="caption" sx={{ width: 80 }}>
            {params.row.organicCtr === 0 ? '0%' : fPercent(params.row.organicCtr)}
          </Typography>
        </Stack>
      ),
      type: 'number',
    },
    {
      field: 'uncapturedPercent',
      headerName: t('dashboard.brandTraffic.percentUncaptured'),
      flex: 1,
      width: 160,
      minWidth: 150,
      renderCell: (params) => (
        <Stack spacing={1} direction="row" alignItems="center" sx={{ px: 1, width: 1, height: 1 }}>
          <LinearProgress
            value={params.row.uncapturedPercent}
            variant="determinate"
            sx={{
              width: 1,
              height: 6,
              backgroundColor: theme.palette.mode === "dark" ? '#454d54' : 'rgb(69, 77, 84, .24)',
              '& .MuiLinearProgress-bar': {
                backgroundColor: '#ADADAD',
              }
            }}
          />
          <Typography variant="caption" sx={{ width: 80 }}>
            {params.row.uncapturedPercent === 0 ? '0%' : fPercent(params.row.uncapturedPercent)}
          </Typography>
        </Stack>
      ),
      type: 'number',
    },
    // paid CPC
    {
      field: 'paidCpc',
      headerName: t('dashboard.brandTraffic.cpc') + ` (${formatDate(filterStore?.start)} - ${formatDate(filterStore?.end)})`,
      width: 200, minWidth: filterStore?.compare ? 220 : 100, maxWidth: 225,
      flex: lgUp ? 1 : undefined,
      renderHeader: () => (
        <div style={{ whiteSpace: 'pre-line', display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
          <span style={{ textAlign: 'right' }}>{t('dashboard.brandTraffic.cpc')}</span>
          {filterStore?.compare && (
            <span style={{ textAlign: 'left' }}>
              {formatDate(filterStore?.start)} - {formatDate(filterStore?.end)}
            </span>
          )}
        </div>
      ),
      renderCell: (params) => (
        <span style={{ color: theme.palette.mode === "dark" ? ' #FF5630' : ' #FF5630' }}>
          {formatCurrency(params.row.paidCpc, currency, locale, false, 2)}
        </span>
      ),
      type: 'number',
    },

    // PAID SHOPPING CPC
    {
      field: 'paidShoppingCpc',
      headerName: `${t('dashboard.brandTraffic.shopping_cpc')} (${formatDate(filterStore?.start)} - ${formatDate(filterStore?.end)})`,
      width: 200, minWidth: filterStore?.compare ? 220 : 120, maxWidth: 225,
      flex: lgUp ? 1 : undefined,
      renderHeader: () => (
        <div style={{ whiteSpace: 'pre-line', display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
          <span style={{ textAlign: 'right' }}>{t('dashboard.brandTraffic.shopping_cpc')}</span>
          {filterStore?.compare && (
            <span style={{ textAlign: 'left' }}>
              {formatDate(filterStore?.start)} - {formatDate(filterStore?.end)}
            </span>
          )}
        </div>
      ),
      renderCell: (params) => (
        <span style={{ color: theme.palette.mode === "dark" ? ' #FF5630' : '#FF5630' }}>
          {formatCurrency(params.row.paidShoppingCpc, currency, locale, false, 2)}
        </span>
      ),
      type: 'number',
    },

    // PAID SEARCH CPC
    {
      field: 'paidSearchCpc',
      headerName: `${t('dashboard.brandTraffic.search_cpc')} (${formatDate(filterStore?.start)} - ${formatDate(filterStore?.end)})`,
      width: 200, minWidth: filterStore?.compare ? 220 : 100, maxWidth: 225,
      flex: lgUp ? 1 : undefined,
      renderHeader: () => (
        <div style={{ whiteSpace: 'pre-line', display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
          <span style={{ textAlign: 'right' }}>{t('dashboard.brandTraffic.search_cpc')}</span>
          {filterStore?.compare && (
            <span style={{ textAlign: 'left' }}>
              {formatDate(filterStore?.start)} - {formatDate(filterStore?.end)}
            </span>
          )}
        </div>
      ),
      renderCell: (params) => (
        <span style={{ color: theme.palette.mode === "dark" ? ' #FF5630' : '#FF5630' }}>
          {formatCurrency(params.row.paidSearchCpc, currency, locale, false, 2)}
        </span>
      ),
      type: 'number',
    },

    // PAID PMAX CPC
    {
      field: 'paidPmaxCpc',
      headerName: `${t('dashboard.brandTraffic.pmax_cpc')} (${formatDate(filterStore?.start)} - ${formatDate(filterStore?.end)})`,
      width: 200, minWidth: filterStore?.compare ? 220 : 100, maxWidth: 225,
      flex: lgUp ? 1 : undefined,
      renderHeader: () => (
        <div style={{ whiteSpace: 'pre-line', display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
          <span style={{ textAlign: 'right' }}>{t('dashboard.brandTraffic.pmax_cpc')}</span>
          {filterStore?.compare && (
            <span style={{ textAlign: 'left' }}>
              {formatDate(filterStore?.start)} - {formatDate(filterStore?.end)}
            </span>
          )}
        </div>
      ),
      renderCell: (params) => (
        <span style={{ color: theme.palette.mode === "dark" ? ' #FF5630' : '#FF5630' }}>
          {formatCurrency(params.row.paidPmaxCpc, currency, locale, false, 2)}
        </span>
      ),
      type: 'number',
    },

    // AD COST
    {
      field: 'adCost',
      headerName: t('dashboard.global.ad_cost') + ` (${formatDate(filterStore?.start)} - ${formatDate(filterStore?.end)})`,
      width: 200, minWidth: filterStore?.compare ? 300 : 100, maxWidth: 225,
      flex: lgUp ? 1 : undefined,
      renderHeader: () => (
        <div style={{ whiteSpace: 'pre-line', display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
          <span style={{ textAlign: 'right' }}>{t('dashboard.global.ad_cost')}</span>
          {filterStore?.compare && (
            <span style={{ textAlign: 'left' }}>
              {formatDate(filterStore?.start)} - {formatDate(filterStore?.end)}
            </span>
          )}
        </div>
      ),
      renderCell: (params) => (
        <span style={{ color: theme.palette.mode === "dark" ? '#FFD066' : '#FFAB00' }}>
          {formatCurrency(params.row.adCost, currency, locale, false, 1)}
        </span>
      ),
      type: 'number',
    },

    // AD COST SHOPPING
    {
      field: 'adCostShopping',
      headerName: `${t('dashboard.global.ad_cost_shopping')} (${formatDate(filterStore?.start)} - ${formatDate(filterStore?.end)})`,
      width: 200, minWidth: filterStore?.compare ? 300 : 100, maxWidth: 225,
      flex: lgUp ? 1 : undefined,
      renderHeader: () => (
        <div style={{ whiteSpace: 'pre-line', display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
          <span style={{ textAlign: 'right' }}>{t('dashboard.global.ad_cost_shopping')}</span>
          {filterStore?.compare && (
            <span style={{ textAlign: 'left' }}>
              {formatDate(filterStore?.start)} - {formatDate(filterStore?.end)}
            </span>
          )}
        </div>
      ),
      renderCell: (params) => (
        <span style={{ color: theme.palette.mode === "dark" ? '#FFD066' : '#FFAB00' }}>
          {formatCurrency(params.row.adCostShopping, currency, locale, false, 1)}
        </span>
      ),
      type: 'number',
    },
    // AD COST PMAX
    {
      field: 'adCostPmax',
      headerName: `${t('dashboard.global.ad_cost_pmax')} (${formatDate(filterStore?.start)} - ${formatDate(filterStore?.end)})`,
      width: 200, minWidth: filterStore?.compare ? 300 : 100, maxWidth: 225,
      flex: lgUp ? 1 : undefined,
      renderHeader: () => (
        <div style={{ whiteSpace: 'pre-line', display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
          <span style={{ textAlign: 'right' }}>{t('dashboard.global.total_ad_cost_pmax')}</span>
          {filterStore?.compare && (
            <span style={{ textAlign: 'left' }}>
              {formatDate(filterStore?.start)} - {formatDate(filterStore?.end)}
            </span>
          )}
        </div>
      ),
      renderCell: (params) => (
        <span style={{ color: theme.palette.mode === "dark" ? '#FFD066' : '#FFAB00' }}>
          {formatCurrency(params.row.adCostPmax, currency, locale, false, 1)}
        </span>
      ),
      type: 'number',
    },

    // AD COST SEARCH
    {
      field: 'adCostSearch',
      headerName: `${t('dashboard.global.ad_cost_search')} (${formatDate(filterStore?.start)} - ${formatDate(filterStore?.end)})`,
      width: 200, minWidth: filterStore?.compare ? 300 : 100, maxWidth: 225,
      flex: lgUp ? 1 : undefined,
      renderHeader: () => (
        <div style={{ whiteSpace: 'pre-line', display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
          <span style={{ textAlign: 'right' }}>{t('dashboard.global.ad_cost_search')}</span>
          {filterStore?.compare && (
            <span style={{ textAlign: 'left' }}>
              {formatDate(filterStore?.start)} - {formatDate(filterStore?.end)}
            </span>
          )}
        </div>
      ),
      renderCell: (params) => (
        <span style={{ color: theme.palette.mode === "dark" ? '#FFD066' : '#FFAB00' }}>
          {formatCurrency(params.row.adCostSearch, currency, locale, false, 1)}
        </span>
      ),
      type: 'number',
    },
  ];

  // Ajouter la fonction hasNonZeroValues
  const hasNonZeroValues = (data: any[], field: string) => {
    return data.some(row => (row[field] || 0) != 0);
  };

  const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>({
    id: false,
    brandSearchesCompare: false,
    brandSearchesDiff: false,
    brandTrafficCompare: false,
    brandTrafficDiff: false,
    shoppingCtr: false,
    shoppingCtrCompare: false,
    shoppingCtrDiff: false,
    pmaxCtr: false,
    pmaxCtrCompare: false,
    pmaxCtrDiff: false,
    searchCtr: false,
    searchCtrCompare: false,
    searchCtrDiff: false,
    paidCpc: !!filterStore.compare && hasNonZeroValues(data, 'paidCpc'),
    paidCpcCompare: !!filterStore.compare && hasNonZeroValues(data, 'paidCpc'),
    paidCpcDiff: !!filterStore.compare && hasNonZeroValues(data, 'paidCpc'),
    paidSearchCpc: false,
    paidSearchCpcCompare: false,
    paidSearchCpcDiff: false,
    paidShoppingCpc: false,
    paidShoppingCpcCompare: false,
    paidShoppingCpcDiff: false,
    paidPmaxCpc: false,
    paidPmaxCpcCompare: false,
    paidPmaxCpcDiff: false,
    organicBrandTrafficCompare: false,
    organicBrandTrafficDiff: false,
    paidBrandTrafficCompare: false,
    paidBrandTrafficDiff: false,
    uncapturedBrandTrafficCompare: false,
    uncapturedBrandTrafficDiff: false,
    blendedCtrCompare: false,
    blendedCtrDiff: false,
    paidCtrCompare: false,
    paidCtrDiff: false,
    organicCtrCompare: false,
    organicCtrDiff: false,
    uncapturedPercentCompare: false,
    uncapturedPercentDiff: false,
    adCostCompare: false,
    adCostDiff: false,
    adCostShopping: false,
    adCostShoppingCompare: false,
    adCostShoppingDiff: false,
    adCostPmax: false,
    adCostPmaxCompare: false,
    adCostPmaxDiff: false,
    adCostSearch: false,
    adCostSearchCompare: false,
    adCostSearchDiff: false,
  });

  useEffect(() => {
    setColumnVisibilityModel((prevModel) => ({
      ...prevModel,
      // Colonnes toujours visibles
      id: false,
      keyword: true,
      brandSearches: true,
      brandTraffic: true,
      organicBrandTraffic: true,
      paidBrandTraffic: true,
      blendedCtr: true,
      paidCpc: true,
      adCost: true,
      organicBrandTrafficCompare: !!filterStore.compare,
      organicBrandTrafficDiff: !!filterStore.compare,
      paidBrandTrafficCompare: !!filterStore.compare,
      paidBrandTrafficDiff: !!filterStore.compare,
      uncapturedBrandTrafficCompare: !!filterStore.compare,
      uncapturedBrandTrafficDiff: !!filterStore.compare,
      brandSearchesCompare: !!filterStore.compare,
      brandSearchesDiff: !!filterStore.compare,
      brandTrafficCompare: !!filterStore.compare,
      brandTrafficDiff: !!filterStore.compare,
      adCostCompare: !!filterStore.compare,
      adCostDiff: !!filterStore.compare,
      paidCtrCompare: !!filterStore.compare,
      paidCtrDiff: !!filterStore.compare,
      blendedCtrCompare: !!filterStore.compare,
      blendedCtrDiff: !!filterStore.compare,
      organicCtrCompare: !!filterStore.compare,
      organicCtrDiff: !!filterStore.compare,
      uncapturedPercentCompare: !!filterStore.compare,
      uncapturedPercentDiff: !!filterStore.compare,
      paidCpcCompare: !!filterStore.compare,
      paidCpcDiff: !!filterStore.compare,

      // Colonnes avancées (visibles uniquement en mode avancé)
      shoppingCtr: isAdvancedView && hasNonZeroValues(data, 'shoppingCtr'),
      pmaxCtr: isAdvancedView && hasNonZeroValues(data, 'pmaxCtr'),
      searchCtr: isAdvancedView && hasNonZeroValues(data, 'searchCtr'),
      paidSearchCpc: isAdvancedView && hasNonZeroValues(data, 'paidSearchCpc'),
      paidShoppingCpc: isAdvancedView && hasNonZeroValues(data, 'paidShoppingCpc'),
      paidPmaxCpc: isAdvancedView && hasNonZeroValues(data, 'paidPmaxCpc'),
      adCostShopping: isAdvancedView && hasNonZeroValues(data, 'adCostShopping'),
      adCostPmax: isAdvancedView && hasNonZeroValues(data, 'adCostPmax'),
      adCostSearch: isAdvancedView && hasNonZeroValues(data, 'adCostSearch'),
      paidShoppingCpcCompare: isAdvancedView && !!filterStore.compare && hasNonZeroValues(data, 'paidShoppingCpc'),
      paidShoppingCpcDiff: isAdvancedView && !!filterStore.compare && hasNonZeroValues(data, 'paidShoppingCpc'),
      paidSearchCpcCompare: isAdvancedView && !!filterStore.compare && hasNonZeroValues(data, 'paidSearchCpc'),
      paidSearchCpcDiff: isAdvancedView && !!filterStore.compare && hasNonZeroValues(data, 'paidSearchCpc'),
      paidPmaxCpcCompare: isAdvancedView && !!filterStore.compare && hasNonZeroValues(data, 'paidPmaxCpc'),
      paidPmaxCpcDiff: isAdvancedView && !!filterStore.compare && hasNonZeroValues(data, 'paidPmaxCpc'),
      adCostShoppingCompare: isAdvancedView && !!filterStore.compare && hasNonZeroValues(data, 'adCostShopping'),
      adCostShoppingDiff: isAdvancedView && !!filterStore.compare && hasNonZeroValues(data, 'adCostShopping'),
      adCostPmaxCompare: isAdvancedView && !!filterStore.compare && hasNonZeroValues(data, 'adCostPmax'),
      adCostPmaxDiff: isAdvancedView && !!filterStore.compare && hasNonZeroValues(data, 'adCostPmax'),
      adCostSearchCompare: isAdvancedView && !!filterStore.compare && hasNonZeroValues(data, 'adCostSearch'),
      adCostSearchDiff: isAdvancedView && !!filterStore.compare && hasNonZeroValues(data, 'adCostSearch'),
      paidCtrShoppingCompare: isAdvancedView && !!filterStore.compare && hasNonZeroValues(data, 'shoppingCtr'),
      paidCtrShoppingDiff: isAdvancedView && !!filterStore.compare && hasNonZeroValues(data, 'shoppingCtr'),
      paidCtrPmaxCompare: isAdvancedView && !!filterStore.compare && hasNonZeroValues(data, 'pmaxCtr'),
      paidCtrPmaxDiff: isAdvancedView && !!filterStore.compare && hasNonZeroValues(data, 'pmaxCtr'),
      paidCtrSearchCompare: isAdvancedView && !!filterStore.compare && hasNonZeroValues(data, 'searchCtr'),
      paidCtrSearchDiff: isAdvancedView && !!filterStore.compare && hasNonZeroValues(data, 'searchCtr'),
    }));
  }, [filterStore.compare, data, isAdvancedView]);


  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });

  const handleRowClick = (params: any) => {
    const clickedDate = params?.row?.date;

    // Vérifiez si clickedDate est une chaîne valide
    if (typeof clickedDate === 'string') {
      const parsedDate = new Date(clickedDate); // Pas besoin de parse, le format ISO est valide

      if (!isNaN(parsedDate.getTime())) { // Vérifiez que la date est valide
        const parsedDateISOString = parsedDate.toISOString();

        if (filterStore.start === parsedDateISOString && filterStore.end === parsedDateISOString) {
          const newStartDate = new Date();
          newStartDate.setMonth(newStartDate.getUTCMonth() - 1);
          newStartDate.setDate(newStartDate.getUTCDate() + 1);

          const newEndDate = new Date();
          newEndDate.setDate(newEndDate.getUTCDate() - 1);

          dispatch(
            setFilterDate({
              start: newStartDate.toISOString(),
              end: newEndDate.toISOString(),
            })
          );
        } else {
          dispatch(
            setFilterDate({
              start: parsedDateISOString,
              end: parsedDateISOString,
            })
          );
        }
      } else {
        console.error("Date invalide :", clickedDate);
      }
    } else {
      console.error("Clicked Date n'est pas une chaîne :", clickedDate);
    }
  };


  const handlePaginationModelChange = (newModel: any) => {
    setPaginationModel(newModel);
  };

  const handleChangeColumnVisibilityModel = (newModel: GridColumnVisibilityModel) => {
    setColumnVisibilityModel(newModel);
  };

  return (
    <Box
      sx={{
        '& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-cell:focus': {
          outline: 'none !important',
        },
        '& .MuiDataGrid-cell': {
          cursor: 'pointer',
        },
      }}
    >
      <DataGridPro
        disableDensitySelector
        autoHeight
        checkboxSelection={false}
        disableRowSelectionOnClick
        rows={data}
        pagination
        onRowClick={handleRowClick}
        paginationModel={paginationModel}
        columns={createColumns(theme, t, true, formatCurrency, workspace?.currency, currentLang.value, formatDate)}
        onPaginationModelChange={handlePaginationModelChange}
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={handleChangeColumnVisibilityModel}
        slots={{
          toolbar: GridToolbar,
        }}
      />
    </Box>
  );
}